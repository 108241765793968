import React,{ Component} from 'react';
import { connect } from 'react-redux';
import {CSSTransition} from 'react-transition-group';
import { actionCreators } from './store';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

//import { actionCreators as loginActionCreators } from '../../pages/login/store';

import{
  BackGroundImageWrapper,
  BackGroundImage,
  MyLogo
}from './style';

class BackGroundVideo extends Component  {

  constructor(props) {
		super(props)
		this.state = {
			show: true
		}
	}

  render(){
    return(
      <Container fluid className={"no-gutters mx-0 px-0"}>
       <BackGroundImageWrapper>
        <Row>
          <Col>
              <CSSTransition
              in={true}
              timeout={ 2333 }
              classNames='fade'
              unmountOnExit
              appear={true}
              >
                <MyLogo />
              </CSSTransition>
              <BackGroundImage />
          </Col>
        </Row>
        </BackGroundImageWrapper>
      </Container>
  )
  }
}

const mapStateToProps = (state) =>{
  return {
  }
};


const mapDispatchToProps = (dispatch) => ({
  closeBackGroundImage(){
    const action = actionCreators.closeBackGroundImageNow();
    dispatch(action);
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(BackGroundVideo);
