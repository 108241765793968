import React,{ useState,useEffect, Fragment} from 'react';
import { connect } from 'react-redux';
import { actionCreators } from './store';

import Navbar from 'react-bootstrap/Navbar';
import Navitem from 'react-bootstrap/Navitem';
import { Link, NavLink } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import "./styles.css";

import {BrowserRouter} from 'react-router-dom';
import {Route, Routes} from "react-router";

import logoPic from '../../statics/logoGreen1.jpg';

//import { actionCreators as loginActionCreators } from '../../pages/login/store';
import{
  NavImgContainer,
  LogoWeibo,
  LogoWYY,
  LogoIns,
  LogoBILI,
  NavImg
}from './style';

const Header = () => {

    const [expanded, setExpanded] = useState(false);

    return(
          <>
          <div className = "navbarwrapper1-style">
            <Navbar expanded={expanded} collapseOnSelect bg="" variant="light">
              <Container  fluid className={"no-gutters mx-0 px-0"}>
                <Navbar.Toggle onClick={() => setExpanded((expanded) => {expanded = !expanded})}
              className = "class2" aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse className = "class1" id="responsive-navbar-nav">
                   <Navitem className = "navitem-width-1">
                   <NavImgContainer>
                       <NavImg className='left'>
                       <LogoWeibo onClick={()=> window.open("https://www.weibo.com/liliwish", "_blank")}/>
                       </NavImg>
                       <NavImg className='left'>
                       <LogoIns onClick={()=> window.open("https://www.instagram.com/liliwish/", "_blank")}/>
                       </NavImg>
                       <NavImg className='left'>
                       <LogoWYY onClick={()=> window.open("https://music.163.com/#/artist/desc?id=12313040", "_blank")}/>
                       </NavImg>
                       <NavImg className='left'>
                       <LogoBILI onClick={()=> window.open("https://space.bilibili.com/385046335", "_blank")}/>
                       </NavImg>
                    </NavImgContainer>
                    </Navitem>

                    <Navitem onClick={() => setExpanded(false)} className = "navitem-width-2"><Link className = "link-engineer-style" key={"engineer"} to="/engineer">E N G I N E E R</Link></Navitem>
                    <Navitem onClick={() => setExpanded(false)} className = "navitem-width-3"><Link key={"home"} to="/"><img  alt=" " className="hover-on-image navitem-display-control-style" src={logoPic} ></img></Link></Navitem>
                    <Navitem onClick={() => setExpanded(false)} className = "navitem-width-4"><Link className = "link-multiartist-style" key={"multiartist"} to="/multiartist">M U L T I - A R T I S T</Link></Navitem>
                    <Navitem onClick={() => setExpanded(false)} className = "navitem-width-5 navitem-display-control-style">Email: wishlili66@outlook.com</Navitem>
                    <Navitem onClick={() => setExpanded(false)} className = "navitem-width-6"><Link className = "link-backhome-style"  key={"backhome"} to="/">H O M E</Link></Navitem>
                   </Navbar.Collapse>
                </Container>
              </Navbar>
          </div >
            <div className = "navbarwrapper2-style">
              <Navbar expanded={expanded} collapseOnSelect expand="lg" bg="" variant="light">
                <Container  fluid className={"no-gutters mx-0 px-0"}>
                <Navbar.Brand>
                  <Navitem onClick={() => setExpanded(false)} className = "navitem-width-3" >
                    <Link key={"home"} to="/"><img  alt=" " className="hover-on-image navitem-display-control-style" src={logoPic} ></img></Link>
                  </Navitem>
                </Navbar.Brand>
                <Navbar.Toggle onClick={() => setExpanded((expanded) => {expanded = !expanded})}
                  className = "class2" aria-controls="responsive-navbar-nav" />
                  <Navbar.Collapse className = "class1" id="responsive-navbar-nav">

                      <Navitem onClick={() => setExpanded(false)} className = "navitem-width-2"><Link className = "link-engineer-style" key={"engineer"} to="/engineer">E N G I N E E R</Link></Navitem>
                      <Navitem onClick={() => setExpanded(false)} className = "navitem-width-4"><Link className = "link-multiartist-style" key={"multiartist"} to="/multiartist">M U L T I - A R T I S T</Link></Navitem>
                      <Navitem onClick={() => setExpanded(false)} className = "navitem-width-6"><Link className = "link-backhome-style"  key={"backhome"} to="/">H O M E</Link></Navitem>
                      <Navitem className = "navitem-width-1">
                      <NavImgContainer>
                          <NavImg className='left'>
                          <LogoWeibo onClick={()=> window.open("https://www.weibo.com/liliwish", "_blank")}/>
                          </NavImg>
                          <NavImg className='left'>
                          <LogoIns onClick={()=> window.open("https://www.instagram.com/liliwish/", "_blank")}/>
                          </NavImg>
                          <NavImg className='left'>
                          <LogoWYY onClick={()=> window.open("https://music.163.com/#/artist/desc?id=12313040", "_blank")}/>
                          </NavImg>
                          <NavImg className='left'>
                          <LogoBILI onClick={()=> window.open("https://space.bilibili.com/385046335", "_blank")}/>
                          </NavImg>
                       </NavImgContainer>
                       </Navitem>


                     </Navbar.Collapse>
                    </Container>
                </Navbar>
              </div>

            </>
    )

};


export default Header;
