import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {actionCreators } from '../../store';

import Shuffle from '../../../../common/shuffle/index'
import "../styles.css";

import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { BioTitle, BioSubTitle, BioArtProCCTVWrapper, BioItem, BioSpace } from '../../style'

class BioArtProCCTV extends PureComponent {

  render(){
    const {bioArtProCCTVTitle, bioArtProCCTVSubTitle, bioArtProCCTVText} = this.props;
    return (<div>
              <BioArtProCCTVWrapper className="bioartprocctvwrapper-style-phone">
              <Container fluid className={"no-gutters mx-0 px-0"}>
                <Row>
                  <Col>
                    {bioArtProCCTVTitle.map((item)=>{
                        return (
                          <Row key = {item}>
                            <Col key = {item}>
                             <BioTitle key = {item}>
                             {item}
                             </BioTitle>
                          </Col>
                        </Row>
                          )
                        })
                      }
                  </Col>
                </Row>
                  <Row>
                    <Col>
                     <BioSpace />
                  </Col>
                  </Row>

                  <Row>
                    <Col>
                    <BioSubTitle>
                     <Shuffle>
                      {bioArtProCCTVSubTitle}
                      </Shuffle>
                    </BioSubTitle>

                    </Col>
                  </Row>

                      {bioArtProCCTVText.map((item)=>{
                          return (
                            <Row key = {item}>
                              <Col key = {item}>
                               <BioItem key = {item}>
                                {item}
                               </BioItem>
                            </Col>
                          </Row>
                            )
                          })
                        }
               </Container>
               </BioArtProCCTVWrapper>
         </div>
    )
  }
}

const mapStateToProps = (state)=>({
    bioArtProCCTVText: state.getIn(['home','bioArtProCCTVText']),
    bioArtProCCTVTitle: state.getIn(['home','bioArtProCCTVTitle']),
    bioArtProCCTVSubTitle: state.getIn(['home','bioArtProCCTVSubTitle']),

});

export default connect(mapStateToProps, null)(BioArtProCCTV);
