import React, {Component} from 'react';

import BioIntro from './components/BioIntro';
import BioIntroMedia from './components/BioIntroMedia';

import BioEngineer from './components/engineer/BioEngineer';
import BioEngPhotoEdu from './components/engineer/BioEngPhotoEdu';
import BioEngPhotoJob from './components/engineer/BioEngPhotoJob';

import BioArtist from './components/artist/BioArtist';
import BioArtProMusicComposer from './components/artist/BioArtProMusicComposer';
import BioArtProSinger from './components/artist/BioArtProSinger';
import BioArtProCCTV from './components/artist/BioArtProCCTV';
import BioArtVideoCCTV from './components/artist/BioArtVideoCCTV';

import BioArtMusicList from './components/artist/BioArtMusicList';

import BioHobby from './components/hobby/BioHobby';
import BioHobbbyVideo from './components/hobby/BioHobbyVideo';
import BioHobbbyPhoto from './components/hobby/BioHobbyPhoto';

import ByebyePhoto from './components/byebye/ByebyePhoto';
import ByebyeSocialLinks from './components/byebye/ByebyeSocialLinks';

import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import {actionCreators} from './store'

import {HomeWrapper
} from './style';

import "./styles.css";

class Home extends Component {
  render(){
    return (
      <HomeWrapper>
      <Container fluid className={"no-gutters mx-0 px-0"}>

      <Row>
        <Col sm={7}>
          <BioIntroMedia className = "display-style"/>
        </Col>
        <Col sm={5}>
          <BioIntro className = "display-style"/>
        </Col>
      </Row>

      <Row>
        <Col sm={8}>
           <BioEngineer className = "display-style"/>
        </Col>
        <Col sm={4}>
           <BioEngPhotoJob className = "display-style" />
        </Col>
      </Row>

      <Row>
        <Col md={{ span: 4, offset: 2 }}>
          <BioEngPhotoEdu className = "display-style"/>
        </Col>
        <Col>
          <BioArtist className = "display-style"/>
        </Col>
      </Row>

      <Row><Col md={{ span: 7, offset: 0 }}><BioArtProMusicComposer  className = "display-style" /><BioArtProSinger  className = "display-style" /></Col><Col><BioArtMusicList  className = "display-style" /></Col></Row>
      <Row><Col  md={{ span: 7, offset: 3 }}><BioArtProCCTV  className = "display-style" /></Col></Row>
      <Row><Col md={{ span: 7, offset: 3 }} ><BioArtVideoCCTV  className = "display-style" /></Col></Row>
      <Row><Col><BioHobby /></Col><Col><Row><Col><BioHobbbyVideo  className = "display-style" /></Col></Row><Row></Row></Col></Row>
      <Row><Col sm={8}><ByebyePhoto  className = "display-style"  /></Col><Col sm={4}><ByebyeSocialLinks  className = "display-style" /></Col></Row>

      </Container>
      </HomeWrapper>
    )
  }
}


export default Home;
