import React, {PureComponent} from 'react';
import { useState,useEffect} from 'react';
import {actionCreators } from '../../store';

import ReactPlayer from 'react-player/lazy';
import "../styles.css";

import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import myvideo from '../../../../statics/video_TVhost.mp4';
import myposter from '../../../../statics/video_TVhost_poster.jpg';
import playIcon from '../../../../statics/video_playIcon.png';

import {BioArtVideoCCTVWrapper } from '../../style'

class BioArtVideoCCTV extends PureComponent {

  constructor(props) {
    super(props);
    // Don't call this.setState() here!
    this.state = {
     width: '62vh',
     height: '35vh',
   };

    this.handleResize = this.handleResize.bind(this);
  }

  render(){

    return (<div>
              <BioArtVideoCCTVWrapper className="bioartvideocctvwrapper-style-phone">
              <Container fluid className={"no-gutters mx-0 px-0"}>
                <Row>
                  <Col>
                    <ReactPlayer
                      className='react-player-style video-box'
                      width={this.state.width}
                      height={this.state.height}
                      url= {myvideo}
                      light={myposter}
                      playing
                      playIcon={<img src={playIcon} className = {"video-playicon"} alt="playIcon" />}
                      controls = {true}
                      config={{ file: {
                                  attributes: {
                                  disablePictureInPicture: false,
                                  crossOrigin: 'anonymous',
                                  controlsList: 'nodownload noremoteplayback'
                                  }
                              }}}
                      />
                  </Col>
                </Row>
               </Container>
               </BioArtVideoCCTVWrapper>
         </div>
    )
  }


  handleResize() {
        if(window.innerWidth < 786){
        this.setState({width:"85vw"});
        this.setState({height:"48vw"});
      }else{
        this.setState({width:window.innerWidth * 0.35 });
        this.setState({height:window.innerWidth * 0.2});
      }
  }

  componentDidMount(){
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount(){
    window.removeEventListener('resize', this.handleResize);
  }


}

export default BioArtVideoCCTV;
