import React, {PureComponent} from 'react';
import { useState,useEffect} from 'react';
import {connect} from 'react-redux';
import {actionCreators } from '../../store';
import ReactPlayer from 'react-player';

import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import myvideo from '../../../../statics/hobby_video_1.mp4';
import myposter from '../../../../statics//hobby_video_post_1.jpg';
import playIcon from '../../../../statics/video_playIcon.png';

import "../styles.css";

import {BioHobbyVideoWrapper} from '../../style'

class BioHobbyVideo extends PureComponent {

  constructor(props) {
    super(props);
    // Don't call this.setState() here!
    this.state = {
     width: '62vh',
     height: '35vh',
   };

    this.handleResize = this.handleResize.bind(this);
  }

  render(){
    return (<div>
              <BioHobbyVideoWrapper className = "biohobbyvideowrapper-style-phone">
              <Container fluid className={"no-gutters mx-0 px-0"}>
                <Row>
                  <Col>
                    <ReactPlayer
                      className='reactplayer-style video-box'
                      url= {myvideo}
                      light={myposter}
                      width={this.state.width}
                      height={this.state.height}
                      playing
                      playIcon={<img src={playIcon} className = {"video-playicon"} alt="playIcon" />}
                      controls = {true}
                      config={{ file: {
                                  attributes: {
                                  disablePictureInPicture: 'false',
                                  crossOrigin: 'anonymous',
                                  controlsList: 'nodownload noremoteplayback'
                                  }
                              }}}
                      />
                  </Col>
                </Row>
               </Container>
               </BioHobbyVideoWrapper>
         </div>
    )
  }

  handleResize() {
    /*    console.log(window.innerWidth);*/
        if(window.innerWidth < 786){
          this.setState({width:"85vw"});
          this.setState({height:"48vw"});
      }else{
        this.setState({width:window.innerWidth * 0.35 });
        this.setState({height:window.innerWidth * 0.2});
      }
  }

  componentDidMount(){
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount(){
    window.removeEventListener('resize', this.handleResize);
  }

}

export default BioHobbyVideo;
