
import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {actionCreators } from '../../store';
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

import img1 from '../../../../statics/img_bro2.jpg';
import img2 from '../../../../statics/img_mik2.jpg';
import img3 from '../../../../statics/img_bju1.jpg';

// Import Swiper styles
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'

import "../styles.css";

import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

// import Swiper core and required modules

import { BioHobbbyPhotoWrapper, BioTitle } from '../../style'
// install Swiper modules
import SwiperCore, {
  Lazy,Autoplay,Pagination,Navigation,Parallax,
} from 'swiper';


SwiperCore.use([Lazy,Autoplay,Pagination,Navigation,Parallax]);

class BioHobbyPhoto extends PureComponent  {

  render(){
    const {bioEngineerMediaEduTitle,bioEngineerMediaEduSubTitle} = this.props;
    return (
      <BioHobbbyPhotoWrapper>
      <Container fluid className={"no-gutters mx-0 px-0"}>
      <Row>
        <Col>
          <BioTitle>{bioEngineerMediaEduTitle}</BioTitle>
        </Col>
      </Row>
      <Row>
        <Col>
          <BioTitle>{bioEngineerMediaEduSubTitle}</BioTitle>
        </Col>
      </Row>
        <Row>
          <Col>

            <>
            <div className = "container-1">
            <Swiper spaceBetween={30}
              lazy={{
                "loadPreNext" : true,
                "loadPrevNextAmount": 1,
              }}
              centeredSlides={true}
              mousewheel={true}
              slidesPerView={1}
              preloadImages={false}
              autoplay={{
              "delay": 7333,
              "disableOnInteraction": false
              }}
              pagination={{
              "clickable": true
              }}
              navigation={true}>
                <SwiperSlide className="swiper-height-hobby">
                  <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
                  <img alt=" "  className="swiper-lazy" data-src={img1}></img>
                  <div className="img-description">Brown University ( USA )</div>
                </SwiperSlide>
                <SwiperSlide className="swiper-height-hobby">
                  <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
                 <img alt=" "  className="swiper-lazy" data-src={img2}></img>
                  <div className="img-description"> Mikkeli University of Applied Sciences ( FINLAND )</div>
                </SwiperSlide>
                <SwiperSlide className="swiper-height-hobby">
                  <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
                 <img alt=" "   className="swiper-lazy" data-src={img3}></img>
                 <div className="img-description">Beijing University of Technology ( CHINA )</div>
                </SwiperSlide>
                </Swiper>
          </div>
        </>
        </Col>
      </Row>


     </Container>

</BioHobbbyPhotoWrapper>


  )
}
}


const mapStateToProps = (state)=>({
    bioEngineerItem: state.getIn(['home','bioEngineerItem']),
    bioEngineerMediaEduTitle: state.getIn(['home','bioEngineerMediaEduTitle']),
    bioEngineerMediaEduSubTitle: state.getIn(['home','bioEngineerMediaEduSubTitle'])


});

export default connect(mapStateToProps, null)(BioHobbyPhoto);
