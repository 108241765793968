
import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {actionCreators } from '../../store';
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

import img1 from '../../../../statics/img_ora1.jpg';
import img2 from '../../../../statics/img_nvi1.jpg';

// Import Swiper styles
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'

import "../styles.css";

import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import {BioEngPhotoJobWrapper,BioTitle } from '../../style'

import SwiperCore, {
  Lazy,Autoplay,Pagination,Navigation,Parallax,
} from 'swiper';

SwiperCore.use([Lazy,Autoplay,Pagination,Navigation,Parallax]);

class BioEngPhotoJob extends PureComponent  {

  render(){
    const {bioEngineerMediaJobTitle, bioEngineerMediaJobSubTitle} = this.props;
    return (
      <BioEngPhotoJobWrapper className="bioengphotojobwrapper-style-phone">
      <Container fluid className={"no-gutters mx-0 px-0"}>
      <Row>
        <Col>
          <BioTitle>{bioEngineerMediaJobTitle}</BioTitle>
        </Col>
      </Row>
      <Row>
        <Col>
          <BioTitle>{bioEngineerMediaJobSubTitle}</BioTitle>
        </Col>
      </Row>
        <Row>
          <Col>

            <>

            <div className = "container-1">
            <Swiper spaceBetween={30}
              lazy={{
                "loadPreNext" : true,
                "loadPrevNextAmount": 1,
              }}
              centeredSlides={true}
              mousewheel={true}
              slidesPerView={1}
              preloadImages={false}
              autoplay={{
              "delay": 7333,
              "disableOnInteraction": false
              }}
              pagination={{
              "clickable": true
              }}
              navigation={true}>
          <SwiperSlide className = "swiper-height-job">
            <img alt=" "   className="swiper-lazy" data-src={img1}></img>
            <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
            <div className="img-description">Oracle Corporation ( USA )</div>
          </SwiperSlide>
          <SwiperSlide  className = "swiper-height-job">
            <img alt=" "   className="swiper-lazy" data-src={img2}></img>
            <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
            <div className="img-description"> Nvidia ( USA )</div>
          </SwiperSlide>
          </Swiper>
          </div>
        </>
        </Col>
      </Row>


     </Container>

</BioEngPhotoJobWrapper>


  )
}
}


const mapStateToProps = (state)=>({
    bioEngineerMediaJobTitle: state.getIn(['home','bioEngineerMediaJobTitle']),
    bioEngineerMediaJobSubTitle: state.getIn(['home','bioEngineerMediaJobSubTitle'])


});

export default connect(mapStateToProps, null)(BioEngPhotoJob);
