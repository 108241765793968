
import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {actionCreators } from '../../store';
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

import img1 from '../../../../statics/foot_img_1.jpg';
import img2 from '../../../../statics/foot_img_2.jpg';

// Import Swiper styles
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'

import "../styles.css";

import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

// import Swiper core and required modules
import SwiperCore, {
  Lazy,Autoplay,Pagination,Navigation,Parallax,
} from 'swiper';

import { ByebyePhotoWrapper, BioTitle } from '../../style'
// install Swiper modules
SwiperCore.use([Lazy,Autoplay,Pagination,Navigation,Parallax]);

class ByebyePhoto extends PureComponent  {

  render(){
    return (
      <ByebyePhotoWrapper className="byebyephotowrapper-style-phone">
      <Container fluid className={"no-gutters mx-0 px-0"}>
      <Row>
        <Col>
          <BioTitle></BioTitle>
        </Col>
      </Row>
      <Row>
        <Col>
          <BioTitle></BioTitle>
        </Col>
      </Row>
        <Row>
          <Col>
            <>
            <div className = "container-1">
              <Swiper spaceBetween={30} lazy={true} centeredSlides={true} autoplay={{
                "delay": 3000,
                "disableOnInteraction": false
              }} pagination={{
                "clickable": true
              }} navigation={true} className="mySwiper">
                <SwiperSlide>
                  <img alt = '' className="swiper-lazy" data-src={img1}></img>
                  <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
                  <div className="img-description"></div>
                </SwiperSlide>
                <SwiperSlide>
                  <img alt = '' className="swiper-lazy" data-src={img2}></img>
                  <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
                  <div className="img-description"></div>
                </SwiperSlide>
                </Swiper>
          </div>
        </>
        </Col>
      </Row>
     </Container>
</ByebyePhotoWrapper>
  )
}
}

const mapStateToProps = (state)=>({
});

export default connect(mapStateToProps, null)(ByebyePhoto);
