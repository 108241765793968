import styled from 'styled-components';

import logoWeibo from '../../statics/logo/logo_weibo.png';
import logoWangYiYun from '../../statics/logo/logo_wangyiyun.png';
import logoInstagram from '../../statics/logo/logo_instagram.png';
import logoBILI from '../../statics/logo/logo_bili.png';

export const LogoWYY = styled.div`
  overflow: hidden;
  position: relative;
  top: 0;
  display: block;
  margin-right:5px;
  padding-bottom:5px;
  width: 30px;
  height: 30px;
  background: url(${logoWangYiYun});
  background-size: contain;
  :hover {
    transform: scale(1.2);
  }
`;

export const LogoBILI = styled.div`
  overflow: hidden;
  position: relative;
  top: 0;
  display: block;
  margin-right:5px;
  padding-bottom:5px;
  width: 30px;
  height: 30px;
  background: url(${logoBILI});
  background-size: contain;
  :hover {
    transform: scale(1.2);
  }
`;


export const LogoWeibo = styled.div`
  overflow: hidden;
  position: relative;
  top: 0;
  display: block;
  margin-right:5px;
  padding-bottom:5px;
  width: 30px;
  height: 30px;
  background: url(${logoWeibo});
  background-size: contain;
  :hover {
  transform: scale(1.3);
  }
`;

export const LogoIns = styled.div`
  overflow: hidden;
  position: relative;
  top: 0;
  margin-right:5px;
  padding-bottom:5px;
  display: block;
  width: 30px;
  height: 30px;
  background: url(${logoInstagram});
  background-size: contain;
  :hover {
  transform: scale(1.3);
  }
`;

export const HomeWrapper = styled.div`
  overflow: hidden; /*触发BFC 为了子元素可以float*/
  width: auto;
  margin: 0 auto;
`;

export const BioItem = styled.div`
  float: left;
  font-family: Helvetica, Sans-Serif;
  line-height: 27px;
  font-size: 0.89rem;
  letter-spacing: 0px;
  word-spacing: 0px;

  .banner-img{
    width: 625px;
    height: 270px;
  }
  &.bio-education{
    padding-left: 133px;
  }
`;

export const BioTitle = styled.div`
  float: left;
  font-family: Helvetica, Sans-Serif;
  font-size: 1.5rem;
  letter-spacing: 7px;
  word-spacing: 7px;

  &.bio-education{
    padding-top: 133px;
    padding-left: 133px;
  }&.bio-media{
    padding-top: 77px;
    padding-left: 0px;
  }&.bio-left-pad-13{
    padding-top: 0px;
    padding-left: 13px;
  }
`;


export const BioSubTitle = styled.div`
  float: left;
  font-family: Helvetica, Sans-Serif;
  font-size: 1.1rem;
  letter-spacing: 7px;
  word-spacing: 7px;

  &.bio-education{
    padding-top: 133px;
    padding-left: 133px;
  }&.bio-media{
    padding-top: 77px;
    padding-left: 0px;
  }
  }&.bio-left-pad-13{
    padding-left: 13px;
  }
`;

export const BioSpace = styled.div`
  height: 13px;
`;

export const BioIntroMediaWrapper = styled.div`
`;

export const BioIntroWrapper = styled.div`
`;

export const BioEngPhotoJobWrapper = styled.div`
`;

export const BioEngPhotoEduWrapper = styled.div`
`;

export const BioEngWrapper = styled.div`
`;

export const BioArtWrapper = styled.div`

`;

export const BioHobWrapper = styled.div`
`;

export const ByebyeWrapper = styled.div`

`;

export const NumWrapper = styled.div`
  float: left;
  font-size: 1.7rem;
  padding: 37px 0px 13px 0px;

`;

export const BioNumForTitle = styled.div`
  float: left;
  font-size: 2.3rem;
  letter-spacing:3px;
  background-color:
  margin: 0 7px;
  padding: 0px 13px 0px 13px;
  background-color: rgb(0,255,0);

`;

export const BioEngSloganWrapper = styled.div`
  float: left;
  margin-left: 177px;
  padding: 77px 0px 0px 30px;

`;

export const BioIntroItem = styled.div`
  float: left;
  font-size: 1.0rem;
  padding: 0px 30px 7px 0px;

`;

export const BioSloganItem = styled.div`
  float: left;
  font-size: 1.0rem;
  letter-spacing:3px;
  padding: 0px 0px 7px 0px;

`;

export const BioArtPhotoTVShowWrapper = styled.div`
  float: left;
  margin-left: 0px;
  padding: 0px 30px 13px 0px;
  width: 80%;
  height: auto;

`;


export const BioEngProMedia1Wrapper = styled.div`
  overflow: hidden;
  float: left;
  position: relative;
  display: block;
  background-size: contain;
  margin: 30px 30px 10px 10px;
  padding-top: 60px 30px 10px 10px;
  width: 90%;
  height: auto;

  .banner-img{
    width: 777px;
    height: 377px;
  }
`;


export const BioEngPhotoSketchWrapper = styled.div`
  overflow: hidden;
  float: left;
  position: relative;
  display: block;
  background-size: contain;
  margin: 30px 30px 10px 10px;
  padding-top: 60px 30px 10px 10px;
  width: 60%;
  height: auto;
  .banner-img{
    width: 100%;
    height: auto;
  }
`;

export const BioEngProIRWrapper = styled.div`
  float: left;
  margin-left: 50px;
  padding-top: 60px;

`;

export const BioEngProCloudWrapper = styled.div`
  float: left;
  margin-left: 150px;
  padding-top: 60px;

`;

export const BioArtProMusicComposerWrapper = styled.div`
`;

export const BioArtProMedia1Wrapper = styled.div`
  float: left;
  margin-left: 50px;
  padding: 43px 30px 0px 0px;
  width: 80%px;
  height: auto;

`;

export const BioArtProChineseDulcimerWrapper = styled.div`
  float: left;
  margin-left: 150px;
  padding-top: 60px;

`;

export const BioArtProCCTVWrapper = styled.div`


`;

export const BioArtProSuperBrainWrapper = styled.div`
  float: left;
  margin-left: 150px;
  padding-top: 60px;

`;

export const BioArtProSingerWrapper = styled.div`
`;

export const BioArtProMusicListWrapper = styled.div`
`;

export const BioArtVideoCCTVWrapper = styled.div`

`;

export const BioArtMediaTVShowWrapper = styled.div`
  float: left;
  margin-left: 43px;
  padding: 43px 30px 0px 0px;

`;

export const BioHobbyVideoWrapper = styled.div`
`;

export const BioHobbbyPhotoWrapper = styled.div`
  float: left;
  margin-left: 43px;
  padding: 43px 30px 0px 0px;
`;

export const ByebyePhotoWrapper = styled.div`

`;

export const ByebyeSocialLinksWrapper = styled.div`

`;

export const NavImgContainer = styled.div`
  overflow: hidden;
  margin-top:30px;
  margin-left: 23px;
  color: #333;
  cursor: pointer;
  &.left{
    float: left;
  }
  &.right{
    float: right;
  }
`;

export const NavImg = styled.div`
  overflow: hidden;
  margin-left: 5px;
  margin-right: 5px;
  color: #333;
  cursor: pointer;
  &.left{
    float: left;
  }
  &.right{
    float: right;
  }
`;
