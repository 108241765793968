import React, {Component} from 'react';
import Header from './common/header/index';
import BackGroundVideo from './common/background/index';
import Home from './pages/home/index';
import Engineer from './pages/engpage/loadable.js';
import Artist from './pages/artpage/loadable.js';
import { BrowserRouter, Route, Routes} from 'react-router-dom';
import store from './store';
import { Provider } from 'react-redux';
import Expire from './common/expire/index';

class App extends Component {

  render(){

    const currentState = store.getState();
    let showFlag = currentState._root.entries[2][1]._root.entries[0][1];

    return (
      <Provider store = {store}>
         <BrowserRouter>
            <Expire delay = "3333">
              <BackGroundVideo />
            </Expire>
            <Header />
              <Routes>
                <Route path="/" extra element={<Home />}></Route>
                <Route path="/engineer" extra element={<Engineer />}></Route>
                <Route path="/multiartist" extra element={<Artist />}></Route>
              </Routes>
         </BrowserRouter>
      </ Provider>
      );
  }
  closeBackGroundImage(showFlag){
    showFlag = false;
  }
  componentDidMount(){

  }

}


export default App;
