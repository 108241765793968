import styled from 'styled-components';
import logoPic from '../../statics/logoGreen1.jpg';
import logoWeibo from '../../statics/logo/logo_weibo.png';
import logoWangYiYun from '../../statics/logo/logo_wangyiyun.png';
import logoInstagram from '../../statics/logo/logo_instagram.png';
import logoBILI from '../../statics/logo/logo_bili.png';

var width1 = "18px";
var height1 = "18px"

export const LogoWeibo = styled.div`
  overflow: hidden;
  position: relative;
  top: 0;
  display: inline-block;
  width: ${width1};
  height: ${height1};
  background: url(${logoWeibo});
  background-size: contain;
  :hover {
  transform: scale(1.3);
  }
`;

export const LogoWYY = styled.div`
  overflow: hidden;
  position: relative;
  top: 0;
  display: inline-block;
  width: ${width1};
  height: ${height1};
  background: url(${logoWangYiYun});
  background-size: contain;
  :hover {
  transform: scale(1.3);
  }
`;

export const LogoBILI = styled.div`
  overflow: hidden;
  position: relative;
  top: 0;
  display: inline-block;
  width: ${width1};
  height: ${height1};
  background: url(${logoBILI});
  background-size: contain;
  :hover {
  transform: scale(1.3);
  }
`;

export const LogoIns = styled.div`
  overflow: hidden;
  position: relative;
  top: 0;
  display: inline-block;
  width: ${width1};
  height: ${height1};
  background: url(${logoInstagram});
  background-size: contain;
  :hover {
  transform: scale(1.3);
  }
`;


export const NavImgContainer = styled.div`
  display: inline-block;
  padding: 0 0px;
  margin-top:1em;
  margin-left: 1em;
  color: #333;
  cursor: pointer;
  &.left{
    float: left;
  }
  &.right{
    float: right;
  }
`;

export const NavImg = styled.div`
  margin-left: 0.5em;
  margin-right: 0.5em;
  cursor: pointer;
  &.left{
    float: left;
  }
  &.right{
    float: right;
  }
`;
