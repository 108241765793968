export const GET_BIO_ENGINEER_ITEM = 'home/GET_BIO_ENGINEER_ITEM';
export const GET_BIO_ENGINEER_ITEM2 = 'home/GET_BIO_ENGINEER_ITEM2';

export const GET_BIO_ENGINEER_TITLE = 'home/GET_BIO_ENGINEER_TITLE';
export const GET_BIO_ENGINEER_SUBTITLE = 'home/GET_BIO_ENGINEER_SUBTITLE';

export const GET_BIO_ENGINEER_MEDIA_EDU_TITLE = 'home/GET_BIO_ENGINEER_MEDIA_EDU_TITLE';
export const GET_BIO_ENGINEER_MEDIA_EDU_SUBTITLE = 'home/GET_BIO_ENGINEER_MEDIA_EDU_SUBTITLE';

export const GET_BIO_ENGINEER_MEDIA_JOB_TITLE = 'home/GET_BIO_ENGINEER_MEDIA_JOB_TITLE';
export const GET_BIO_ENGINEER_MEDIA_JOB_SUBTITLE = 'home/GET_BIO_ENGINEER_MEDIA_JOB_SUBTITLE';

export const GET_BIO_ENGINEER_MEDIA_IMG = 'home/GET_BIO_ENGINEER_MEDIA_IMG';

export const GET_BIO_ENGINEER_PHOTO_NVIDIA_TITLE = 'home/GET_BIO_ENGINEER_PHOTO_NVIDIA_TITLE';

export const GET_BIO_ENGINEER_SLOGAN = 'home/GET_BIO_ENGINEER_SLOGAN';
export const GET_BIO_ARTIST_SLOGAN = 'home/GET_BIO_ARTIST_SLOGAN';
export const GET_BIO_INTRO_SLOGAN = 'home/GET_BIO_INTRO_SLOGAN';

export const GET_BIO_ENG_PRO_Sketch_TITLE = 'home/GET_BIO_ENG_PRO_Sketch_TITLE';
export const GET_BIO_ENG_PRO_Sketch_SUBTITLE = 'home/GET_BIO_ENG_PRO_Sketch_SUBTITLE';
export const GET_BIO_ENG_PRO_Sketch_TEXT = 'home/GET_BIO_ENG_PRO_Sketch_TEXT';

export const GET_BIO_ENG_PRO_IR_TITLE = 'home/GET_BIO_ENG_PRO_IR_TITLE';
export const GET_BIO_ENG_PRO_IR_SUBTITLE = 'home/GET_BIO_ENG_PRO_IR_SUBTITLE';
export const GET_BIO_ENG_PRO_IR_TEXT = 'home/GET_BIO_ENG_PRO_IR_TEXT';

export const GET_BIO_ENG_PRO_CLOUD_TITLE = 'home/GET_BIO_ENG_PRO_COUD_TITLE';
export const GET_BIO_ENG_PRO_CLOUD_SUBTITLE = 'home/GET_BIO_ENG_PRO_CLOUD_SUBTITLE';
export const GET_BIO_ENG_PRO_CLOUD_TEXT = 'home/GET_BIO_ENG_PRO_CLOUD_TEXT';

export const GET_BIO_ART_ITEM = 'home/GET_BIO_ART_ITEM';
export const GET_BIO_ART_TITLE = 'home/GET_BIO_ART_TITLE';
export const GET_BIO_ART_ITEM2 = 'home/GET_BIO_ART_ITEM2';
export const GET_BIO_ART_SUBTITLE = 'home/GET_BIO_ART_SUBTITLE';

export const GET_BIO_ART_PRO_MUSIC_COMPOSER_TITLE = 'home/GET_BIO_ART_PRO_MUSIC_COMPOSER_TITLE';
export const GET_BIO_ART_PRO_MUSIC_COMPOSER_SUBTITLE = 'home/GET_BIO_ART_PRO_MUSIC_COMPOSER_SUBTITLE';
export const GET_BIO_ART_PRO_MUSIC_COMPOSER_TEXT = 'home/GET_BIO_ART_PRO_MUSIC_COMPOSER_TEXT';

export const GET_BIO_ART_PRO_CHINESE_DULCIMER_TITLE = 'home/GET_BIO_ART_PRO_CHINESE_DULCIMER_TITLE';
export const GET_BIO_ART_PRO_CHINESE_DULCIMER_SUBTITLE = 'home/GET_BIO_ART_PRO_CHINESE_DULCIMER_SUBTITLE';
export const GET_BIO_ART_PRO_CHINESE_DULCIMER_TEXT = 'home/GET_BIO_ART_PRO_CHINESE_DULCIMER_TEXT';

export const GET_BIO_ART_PRO_CCTV_TITLE = 'home/GET_BIO_ART_PRO_CCTV_TITLE';
export const GET_BIO_ART_PRO_CCTV_SUBTITLE = 'home/GET_BIO_ART_PRO_CCTV_SUBTITLE';
export const GET_BIO_ART_PRO_CCTV_TEXT = 'home/GET_BIO_ART_PRO_CCTV_TEXT';

export const GET_BIO_ART_PRO_SUPER_BRAIN_TITLE = 'home/GET_BIO_ART_PRO_SUPER_BRAIN_TITLE';
export const GET_BIO_ART_PRO_SUPER_BRAIN_SUBTITLE = 'home/GET_BIO_ART_PRO_SUPER_BRAIN_SUBTITLE';
export const GET_BIO_ART_PRO_SUPER_BRAIN_TEXT = 'home/GET_BIO_ART_PRO_SUPER_BRAIN_TEXT';

export const GET_BIO_ART_PRO_SINGER_TITLE = 'home/GET_BIO_ART_PRO_SINGER_TITLE';
export const GET_BIO_ART_PRO_SINGER_SUBTITLE = 'home/GET_BIO_ART_PRO_SINGER_SUBTITLE';
export const GET_BIO_ART_PRO_SINGER_TEXT = 'home/GET_BIO_ART_PRO_SINGER_TEXT';

export const GET_BIO_HOB_TITLE = 'home/GET_BIO_HOB_TITLE';
export const GET_BIO_HOB_SUBTITLE = 'home/GET_BIO_HOB_SUBTITLE';
export const GET_BIO_HOB_ITEM = 'home/GET_BIO_HOB_ITEM';
export const GET_BIO_HOB_ITEM_1 = 'home/GET_BIO_HOB_ITEM_1';
export const GET_BIO_HOB_ITEM_2 = 'home/GET_BIO_HOB_ITEM_2';
export const GET_BIO_HOB_ITEM_3 = 'home/GET_BIO_HOB_ITEM_3';
export const GET_BIO_HOB_ITEM_4 = 'home/GET_BIO_HOB_ITEM_4';
