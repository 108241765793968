import Loadable from 'react-loadable';
import React from 'react';
import ReactLoading from 'react-loading';

const LoadableComponent = Loadable({
  loader: () => import('./'),/*异步加载index.js*/
  loading(){ /*在loading的时候，显示一些临时内容*/
    return <ReactLoading type={"Cylon"} color={"#00FF7F"} height={'20%'} width={'20%'} />
  }
});

export default () => <LoadableComponent/>
