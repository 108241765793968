import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {actionCreators } from '../../store';
import Typerwriter from 'typewriter-effect';

import "../styles.css";

import Shuffle from '../../../../common/shuffle/index'

import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { BioNumForTitle, BioTitle,BioSpace, BioArtWrapper, BioSubTitle,BioItem } from '../../style'

class BioArtist extends PureComponent {

  render(){
    const {bioArtItem, bioArtItem2, bioArtSubTitle,bioArtTitle} = this.props;
    return (<div>
              <BioArtWrapper className={"bioartwrapper-style-phone"}>
              <Container fluid className={"no-gutters mx-0 px-0"}>
                <Row>
                  <Col>
                  <BioNumForTitle>
                  <Typerwriter  options={{
                       autoStart: true,
                       loop: true,
                     }}
                   onInit={(typerwriter) =>{
                    typerwriter.typeString('TWO').pauseFor(3333).start();
                  }} />
                  </BioNumForTitle>
                  </Col>
                </Row>

                <Row>
                  <Col>
                  <BioTitle>
                    {bioArtTitle}
                  </BioTitle>
                  </Col>
                </Row>

                <Row>
                  <Col>
                   <BioSpace />
                  </Col>
                </Row>

                <Row>
                  <Col>
                  <BioSubTitle>
                    <Shuffle>
                      {bioArtSubTitle.get(0)}
                    </Shuffle>
                  </BioSubTitle>
                  </Col>
                </Row>


                {bioArtItem.map((item)=>{
                    return (
                      <Row key = {item}>
                        <Col key = {item}>
                         <BioItem key = {item}>
                          {item}
                         </BioItem>
                      </Col>
                    </Row>
                      )
                    })
                  }

                <Row>
                  <Col>
                   <BioSpace />
                 </Col>
                </Row>

                <Row>
                  <Col>
                  <BioSubTitle>
                   <Shuffle>
                    {bioArtSubTitle.get(1)}
                   </Shuffle>
                  </BioSubTitle>
                  </Col>
                </Row>
                {bioArtItem2.map((item)=>{
                    return (
                      <Row key = {item}>
                        <Col key = {item}>
                         <BioItem key = {item}>
                          {item}
                         </BioItem>
                      </Col>
                    </Row>
                      )
                    })
                  }
               </Container>
               </BioArtWrapper>
         </div>
    )
  }
}

const mapStateToProps = (state)=>({
    bioArtItem: state.getIn(['home','bioArtistItem']),
    bioArtTitle: state.getIn(['home','bioArtistTitle']),
    bioArtItem2: state.getIn(['home','bioArtistItem2']),
    bioArtSubTitle: state.getIn(['home','bioArtistSubTitle'])


});

export default connect(mapStateToProps, null)(BioArtist);
