import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {actionCreators } from '../store';
// Import Swiper React components

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";


import img1 from '../../../statics/profile.jpg';


// Import Swiper styles
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'

import "./styles.css";

import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';


import { BioIntroMediaWrapper} from '../style'

// import Swiper core and required modules
import SwiperCore, {
  Parallax,Pagination,Navigation
} from 'swiper';

// install Swiper modules
SwiperCore.use([Parallax,Pagination,Navigation]);


class BioIntroMedia extends PureComponent  {

  render(){
    return (
      <BioIntroMediaWrapper className="biointromediawrapper-style-phone">
      <Container fluid className={"no-gutters mx-0 px-0"}>
        <Row>
          <Col>
          <>
          <div className="container-2">

          <Swiper style={{'--swiper-navigation-color': '#fff'}}autoplay={{
            "delay": 2500,
            "disableOnInteraction": false
          }}
          speed={600} mousewheel={true} parallax={true} pagination={{
          "clickable": true
        }} navigation={true} className="mySwiper">
          <div slot="container-start" className="parallax-bg"  data-swiper-parallax="-23%" style={{backgroundImage: `url(${img1})`}}></div>
          <SwiperSlide className="swiper-fixed-height">
          <div className="title" data-swiper-parallax="-300">LI LI</div>
          <div className="subtitle" data-swiper-parallax="-200">Engineer & Multi-Artist</div>
          <div className="text" data-swiper-parallax="-100">
             And I code this web!
          </div>
          </SwiperSlide>

          <SwiperSlide>
             <div className="title" data-swiper-parallax="-300">Engineer</div>
             <div className="subtitle" data-swiper-parallax="-200"></div>
             <div className="text" data-swiper-parallax="-100">
               <div> - Two Bachelor Degrees in China & Finland</div>
               <div> - Master Degree from Brown University at USA</div>
               <div> - Former Software Developer at Nvidia Corporation</div>
               <div> - Former Software Developer at Oracle Corporation</div>
             </div>
           </SwiperSlide>

           <SwiperSlide>
             <div className="title" data-swiper-parallax="-300">Multi-Artist</div>
             <div className="subtitle" data-swiper-parallax="-200"></div>
             <div className="text" data-swiper-parallax="-100">
               <div> - Digtal Artist</div>
               <div> - Music Composer</div>
               <div> - Singer</div>
               <div> - Chinese Dulcimer</div>
             </div>
           </SwiperSlide>
           </Swiper>
           </div>
           </>
        </Col>
      </Row>
     </Container>

</BioIntroMediaWrapper>
  )
}
}

const mapStateToProps = (state)=>({
});

export default connect(mapStateToProps, null)(BioIntroMedia);
