import styled from 'styled-components';
import backgroundPic from '../../statics/background.gif';
import logoPic from '../../statics/logoGreen1.jpg';

var windowleft= (window.innerWidth * 0.5-39)+"px";
var windowheight= (window.innerHeight * 0.5-39)+"px";

export const MyLogo = styled.div`
  overflow: hidden;
  position: absolute;
  top: ${windowheight};
  left: ${windowleft};
  display: block;
  width: 77px;
  height: 77px;
  background: url(${logoPic});
  background-size: contain;
  /*入场动画开始*/
  &.fade-enter {
    opacity: 0;
  }
  /*入场动画过程*/
  &.fade-enter-active {
    opacity: 1;
    transition: opacity 1s ease-in;
  }
  /*入场动画结束*/
  &.fade-enter-done {
    opacity: 1;
  }
  /*离场动画开始*/
  &.fade-exit {
    opacity: 1;
  }
  /*离场动画过程*/
  &.fade-exit-active {
    opacity: 0;
    transition: opacity 1s ease-in;
  }
  /*离场动画结束*/
  &.fade-exit-done {
    opacity: 0;
  }
  /*页面第一次加载时的开始状态*/
  &.fade-appear {
    opacity: 0;
  }
  /*页面第一次加载时的动画过程*/
  &.fade-appear-active {
    opacity: 1;
    transition: opacity 1s ease-in;
  }
`;


export const BackGroundImageWrapper = styled.div`
  .background-image-style {
    display: none;
    width:100%;
    height:100vh;
  }
`;

export const BackGroundImage = styled.div`
  overflow: hidden;
  z-index: -1;
  height: 100vh;
  background-image: url(${backgroundPic});
`;
