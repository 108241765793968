import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {actionCreators } from '../../store';
import Typerwriter from 'typewriter-effect';

import Shuffle from '../../../../common/shuffle/index'

import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import "../styles.css";

import {LogoIns, BioNumForTitle, BioTitle,BioSpace, BioHobWrapper, BioSubTitle,BioItem } from '../../style'

class BioHobby extends PureComponent {

  render(){

    const {bioHobItem, bioHobItem2, bioHobItem3, bioHobItem4, bioHobSubTitle,bioHobTitle} = this.props;
    return (<div>
              <BioHobWrapper className="biohobwrapper-style-phone">
              <Container fluid className={"no-gutters mx-0 px-0"}>
                <Row>
                  <Col>
                  <BioNumForTitle>
                    THREE
                  </BioNumForTitle>
                  </Col>
                </Row>

                <Row>
                  <Col>
                  <BioTitle>
                    {bioHobTitle}
                  </BioTitle>
                  </Col>
                </Row>

                <Row>
                  <Col>
                   <BioSpace />
                  </Col>
                </Row>

                <Row>
                  <Col>
                  <BioSubTitle>
                   <Shuffle>
                    {bioHobSubTitle.get(0)}
                   </Shuffle>
                  </BioSubTitle>
                  </Col>
                </Row>

                {bioHobItem.map((item)=>{
                    return (
                      <Row key = {item}>
                        <Col key = {item}>
                         <BioItem key = {item}>
                          {item}
                         </BioItem>
                      </Col>
                    </Row>
                      )
                    })
                  }

                <Row>
                  <Col>
                   <BioSpace />
                 </Col>
                </Row>

                <Row>
                  <Col>
                  <BioSubTitle>
                    {bioHobSubTitle.get(1)}
                  </BioSubTitle>
                  </Col>
                </Row>
                {bioHobItem2.map((item)=>{
                    return (
                      <Row key = {item}>
                        <Col key = {item}>
                         <BioItem key = {item}>
                          {item}
                         </BioItem>
                      </Col>
                    </Row>
                      )
                    })
                  }

                  <Row>
                    <Col>
                     <BioSpace />
                   </Col>
                  </Row>

                  <Row>
                    <Col>
                    <BioSubTitle>
                    <Typerwriter  options={{
                         autoStart: true,
                         loop: true,
                       }}
                         onInit={(typerwriter) =>{
                          typerwriter.typeString(`${bioHobSubTitle.get(2)}`).start();
                        }} />
                    </BioSubTitle>
                    </Col>
                  </Row>
                  {bioHobItem3.map((item)=>{
                      return (
                        <Row key = {item}>
                          <Col key = {item}>
                           <BioItem key = {item}>
                              {item}
                           </BioItem>
                        </Col>
                      </Row>
                        )
                      })
                    }

                    <Row>
                      <Col>
                       <BioSpace />
                     </Col>
                    </Row>

                    <Row>
                    <Col sm={1}>
                      <LogoIns onClick={()=> window.open("https://www.instagram.com/liliwish/", "_blank")}/>
                    </Col>
                      <Col>
                      <BioSubTitle>
                       <Shuffle>
                        {bioHobSubTitle.get(3)}
                        </Shuffle>
                      </BioSubTitle>
                      </Col>
                    </Row>
                    {bioHobItem4.map((item)=>{
                        return (
                          <Row key = {item}>
                            <Col key = {item}>
                             <BioItem key = {item}>
                              {item}
                             </BioItem>
                          </Col>
                        </Row>
                          )
                        })
                      }
               </Container>
               </BioHobWrapper>
         </div>
    )
  }
}

const mapStateToProps = (state)=>({
    bioHobItem: state.getIn(['home','bioHobbyItem']),
    bioHobItem2: state.getIn(['home','bioHobbyItem2']),
    bioHobItem3: state.getIn(['home','bioHobbyItem3']),
    bioHobItem4: state.getIn(['home','bioHobbyItem4']),
    bioHobTitle: state.getIn(['home','bioHobbyTitle']),
    bioHobSubTitle: state.getIn(['home','bioHobbySubTitle'])
});

export default connect(mapStateToProps, null)(BioHobby);
