import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {actionCreators } from '../store';
import Shuffle from '../../../common/shuffle/index';
import Typerwriter from 'typewriter-effect';

import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { BioSloganItem, BioIntroWrapper, NumWrapper} from '../style'

class BioIntro extends PureComponent {

  render(){
    const {bioIntroSlogan} = this.props;
    return (<div>
              <BioIntroWrapper className="biointrowrapper-style-phone">
              <Container fluid className={"no-gutters mx-0 px-0"}>
                <Row>
                  <Col>
                  <NumWrapper>
                    <Shuffle>
                      <div>I N T R O </div>
                    </Shuffle>
                  </NumWrapper>
                  </Col>
                </Row>

                <Row>
                 <Col>

                    <Row>
                    <Col>
                    <BioSloganItem>
                    <Typerwriter  options={{
                         autoStart: true,
                         loop: true,
                       }}
                     onInit={(typerwriter) =>{
                      typerwriter.typeString('I AM LI LI').pauseFor(3333).start();
                    }} />
                    </BioSloganItem>
                    </Col>
                    </Row>
                  {  bioIntroSlogan.map((item)=>{
                      return (
                        <Row key = {item}>
                          <Col key = {item}>
                           <BioSloganItem key = {item}>
                             {item}
                         </BioSloganItem>
                        </Col>
                      </Row>
                        )
                      })
                    }
                  </Col>
                </Row>
               </Container>
               </BioIntroWrapper>
         </div>
    )
  }
}

const mapStateToProps = (state)=>({
    bioIntroSlogan: state.getIn(['home','bioIntroSlogan']),
});

export default connect(mapStateToProps, null)(BioIntro);
