import * as constants from './constants';
import { fromJS } from 'immutable';

const defaultState= fromJS({
   showBackGoundImage: true

/*  focused: false,
    mouseIn: false,
    list: [],
    page: 1,
    totalPage: 1 */
});


export default (state = defaultState, action) =>{
   switch(action.type){
    case constants.CLOSE_BACKGROUND_IMAGE:
      return state.set('showBackGoundImage', false);
    default:
      return state;
    }
};
