import * as constants from './constants';
import { fromJS } from 'immutable';

const defaultState= fromJS({
  logoImgLinks : ["https://www.weibo.com/liliwish",
                  "https://www.instagram.com/liliwish/",
                  "https://music.163.com/#/artist/desc?id=12313040",
                  "https://space.bilibili.com/385046335"
                ],
  logoImgStatics :["../../statics/logo/logo_weibo.png",
                   "../../statics/logo/logo_instagram.png",
                   "../../statics/logo/logo_wangyiyun.png",
                   "../../statics/logo/logo_bili.png",
                   "../../statics/logo/logo_ins.png"
                   ]

/*  focused: false,
    mouseIn: false,
    list: [],
    page: 1,
    totalPage: 1 */
});
export default (state = defaultState, action) =>{
  switch(action.type){
    case constants.GET_LOGO_IMG_LINKS:
      return state.get('logoImgLinks')
    case constants.GET_LOGO_IMG_STATICS:
      return state.get('logoImgStatics')
    default:
      return state;
    }
};
