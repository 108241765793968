import { fromJS } from 'immutable';
import * as constants from './constants';

const defaultState= fromJS({
  bioIntroSlogan :[ "ENGINEER & MULTI - ARTIST",
                    "And I Code This Web!!!"],
  bioEngineerSlogan :[ "AS AN ENGINEER" ,
                     "I LOVE CODING"],
  bioArtistSlogan :[ "I AM LI LI",
                     "ENGINEER & MULTI - ARTIST",
                     "AND I CODE THIS WEB！！！"],

  bioEngineerTitle : "FRONT-END ENGINEER & PRODUCT MANAGER",
  bioEngineerSubTitle :[ "EDUCATION",
                         "JOB"
                        ],
  bioEngineerItem : ["HIGH SCHOOL (CHINA, Beijing) : The Affiliated High School of Peking University",
                     "BACHELOR (CHINA, Beijing) : Beijing University of Technology (EE)",
                     "BACHELOR (FINLAND, Mikkeli) : Mikkeli University of Applied Sciences (CS)",
                     "MASTER (USA, Providence) : Brown University (AI, CV)",
                  ],
  bioEngineerItem2 : ["NVIDIA CORPORATION (USA, Santa Clara) : Software Developer for Game Device Shield",
                     "ORACLE CORPORATION (USA, San Francisco) : Front-End Engineer for Spatial & Graph Database"
                  ],


  bioArtistTitle : "MULTI - ARTIST",
  bioArtistSubTitle :[ "EDUCATION",
                         "ART WORK"
                        ],
  bioArtistItem : ["CHINESE DULCIMER (Since 7-year old) : One of Chinese Traditional Instruments",
                  "BROWN UNIVERSITY (MINOR) MUSICAL : lots of musicals from Disney "
                ],
  bioArtistItem2 : ["MUSIC CREATOR : Orignal songs over 300+ Pieces, Various styles: Pop, Rap etc.",
                  "SINGER & RAPPER : I write songs and sing them",
                  "ACTOR : Why not? MV 《TELEx TELEXs - 16090 feat. Mommys Boy》"
                ],

  bioEngineerMediaEduTitle : "SCHOOL",
  bioEngineerMediaEduSubTitle : "CHINA-FINLAND-USA",
  bioEngineerMediaJobTitle : "JOB",
  bioEngineerMediaJobSubTitle : "ORACLE - NVIDIA",

  bioEngPhotoNvidiaTitle : "LIFE @ NVIDIA CORPORATION",

  bioMediaImg: ["../../../statics/img_bju1.jpg",
             "../../../statics/img_mik2.jpg",
             "../../../statics/img_bro2.jpg"
           ],

  bioEngProSketchTitle : ["PROJECT Eg 1 :", "Human SKetches Classification"],
  bioEngProSketchSubTitle : "@ BROWN UNIVERSITY",
  bioEngProSketchText : ["Using proper algorithm such as SIFT,SVM etc. to classify human sketches.",
                    "This project can be used for Human Hand Writting Classification",
                    "which will contribute to Automatic Scoring System for College Entrance Examination,",
                    "Hand Writting Scanning etc.",
                  ],

  bioEngProIRTitle : ["PROJECT Eg 2 :", "IR Transceiver"],
  bioEngProIRSubTitle : "Project Leader @ NVIDIA CORPORATION",
  bioEngProIRText : ["Add an IR Transceiver on Android Shield Game Device to be a Remote Control for TV",
                    "Data Analysis for Current Game Market, PWM Wave Research, Driver Code",
                    "Implementation & Testing on TV to turn it on and off "
                  ],

  bioEngProCloudTitle: ["PROJECT Eg 3 :", "Cloud Database Visualization & Management System"],
  bioEngProCloudSubTitle:  "Project Leader @ ORACLE CORPORATION",
  bioEngProCloudText: ["Build a Web-based visualization & management system for 2D & 3D datatypes",
                    "Add visualization tools, data analysis model by drag & drop motion features ",
                    "so that customers don't need to learn SQL query.",
                    "This project will contribute to Smart City, Internet of Things, Digital Twin."
                   ],

   bioArtProMusicComposerTitle : ["MUSIC COMPOSER :", "MIDI? No, I Program Music"],
   bioArtProMusicComposerSubTitle : "",
   bioArtProMusicComposerText : ["All by myself : lyrics, melody, instrumental, recording, mixing.",
                     "I have 300+ pieces original songs, styles vary from Pop to Rap",
                     "My music is hard to express, sometimes pure, sometimes naughty!",
                     "Currenly, I am composing for Chinese Traditional Poems from Tang or Song Dynasty",
                   ],

   bioArtProChineseDulcimerTitle : ["CHINESE DULCIMER :", "em...a strange Instrument with beautiful voice"],
   bioArtProChineseDulcimerSubTitle : "More than 10 years...",
   bioArtProChineseDulcimerText : ["The instrument looks strange, an isosceles trapezoid shape for my frist glance",
                     "but after that glance, I began to study since 7 years old",
                     "I am so grateful to all my teachers whom I even forgot their names since I was 7."],

   bioArtProSingerTitle : ["SINGER :", "Sing my songs!"],
   bioArtProSingerSubTitle : "",
   bioArtProSingerText : ["More ? : 微博 B站 小红书 网易云 @李礼是只攻城狮, Instagram @liliwish"],

   bioArtProCCTVTitle: ["CCTV TV HOST COMPETATION:", "I Made It!"],
   bioArtProCCTVSubTitle:  "Learned a lot.",
   bioArtProCCTVText: [" As what I said in this competation : Tech makes more people enjoy the earth",
                    "but it's the warm heart that make people enjoy themselves. "],

   bioArtProSuperBrainTitle: ["SUPER BRAIN", "I Played It!"],
   bioArtProSuperBrainSubTitle:  "Just a game!",
   bioArtProSuperBrainText: ["I made some good friends, as they all got eliminated, I didn't wanna fight anymore...","I was very emotional at that moment!", "Like what I said : I don't know why, hopefully, you know it."],

   bioHobbyTitle: "LOTS OF HOBBIES",
   bioHobbySubTitle: ["SPORTS", "TRAVELLING", "PHOTOGRAPHY", "FASHION"],
   bioHobbyItem: ["I love badmintons, soccer, archery, cycling, so many many many...!",
                  "I dream to be an athlete when I was before 7 years old"],
   bioHobbyItem2: ["I wanna travel all the countries, until now, I've been to 12 countries, over 20 National Parks"],
   bioHobbyItem3: ["I like slow life, so I take photos to be still.",
                  ""],
   bioHobbyItem4: ["I like Rick Owens, Alexander Mcqueen, HBA"],

});

export default (state = defaultState, action) =>{
  switch(action.type){

    case constants.GET_BIO_ENGINEER_SLOGAN:
      return state.get('bioEngineerSlogan');
    case constants.GET_BIO_INTRO_SLOGAN:
      return state.get('bioIntroSlogan');
    case constants.GET_BIO_ARTIST_SLOGAN:
      return state.get('bioArtistSlogan');

    case constants.GET_BIO_ENGINEER_ITEM:
      return state.get('bioEngineerItem');
    case constants.GET_BIO_ENGINEER_TITLE:
      return state.get('bioEngineerTitle');
    case constants.GET_BIO_ENGINEER_ITEM2:
      return state.get('bioEngineerItem2');
    case constants.GET_BIO_ENGINEER_SUBTITLE:
      return state.get('bioEngineerSubTitle');

    case constants.GET_BIO_ART_ITEM:
      return state.get('bioArtistItem');
    case constants.GET_BIO_ART_TITLE:
      return state.get('bioArtistTitle');
    case constants.GET_BIO_ART_ITEM2:
      return state.get('bioArtistItem2');
    case constants.GET_BIO_ART_SUBTITLE:
      return state.get('bioArtistSubTitle');

    case constants.GET_BIO_ENGINEER_MEDIA_EDU_TITLE:
      return state.get('bioEngineerMediaEduTitle');
    case constants.GET_BIO_ENGINEER_MEDIA_EDU_SUBTITLE:
      return state.get('bioEngineerMediaEduSubTitle');
    case constants.GET_BIO_ENGINEER_MEDIA_JOB_TITLE:

      return state.get('bioEngineerMediaJobTitle');
    case constants.GET_BIO_ENGINEER_MEDIA_JOB_SUBTITLE:
      return state.get('bioEngineerMediaJobSubTitle');

    case constants.GET_BIO_ENGINEER_PHOTO_NVIDIA_TITLE:
      return state.get('bioEngPhotoNvidiaTitle');

    case constants.GET_BIO_ENGINEER_MEDIA_IMG:
      return state.get('bioMediaImg');

    case constants.GET_BIO_ENG_PRO_Sketch_TITLE:
      return state.get('bioEngProSketchTitle');
    case constants.GET_BIO_ENG_PRO_Sketch_SUBTITLE:
      return state.get('bioEngProSketchSubtitle');
    case constants.GET_BIO_ENG_PRO_Sketch_TEXT:
      return state.get('bioEngProSketchText');

    case constants.GET_BIO_ENG_PRO_IR_TITLE:
      return state.get('bioEngProIRTitle');
    case constants.GET_BIO_ENG_PRO_IR_SUBTITLE:
      return state.get('bioEngProIRSubtitle');
    case constants.GET_BIO_ENG_PRO_IR_TEXT:
      return state.get('bioEngProIRText');

    case constants.GET_BIO_ENG_PRO_CLOUD_TITLE:
      return state.get('bioEngProCloudTitle');
    case constants.GET_BIO_ENG_PRO_CLOUD_SUBTITLE:
      return state.get('bioEngProCloudSubtitle');
    case constants.GET_BIO_ENG_PRO_CLOUD_TEXT:
      return state.get('bioEngProCloudText');

    case constants.GET_BIO_ART_PRO_MUSIC_COMPOSER_TITLE:
      return state.get('bioArtProMusicComposerTitle');
    case constants.GET_BIO_ART_PRO_MUSIC_COMPOSER_SUBTITLE:
      return state.get('bioArtProMusicComposerSubtitle');
    case constants.GET_BIO_ART_PRO_MUSIC_COMPOSER_TEXT:
      return state.get('bioArtProMusicComposerText');

    case constants.GET_BIO_ART_PRO_CHINESE_DULCIMER_TITLE:
      return state.get('bioArtProChineseDulcimerTitle');
    case constants.GET_BIO_ART_PRO_CHINESE_DULCIMER_SUBTITLE:
      return state.get('bioArtProChineseDulcimerSubtitle');
    case constants.GET_BIO_ART_PRO_CHINESE_DULCIMER_TEXT:
      return state.get('bioArtProChineseDulcimerText');

    case constants.GET_BIO_ART_PRO_CCTV_TITLE:
      return state.get('bioArtProCCTVTitle');
    case constants.GET_BIO_ART_PRO_CCTV_SUBTITLE:
      return state.get('bioArtProCCTVSubtitle');
    case constants.GET_BIO_ART_PRO_CCTV_TEXT:
      return state.get('bioArtProCCTVText');

    case constants.GET_BIO_ART_PRO_SUPER_BRAIN_TITLE:
      return state.get('bioArtProSuperBrainTitle');
    case constants.GET_BIO_ART_PRO_SUPER_BRAIN_SUBTITLE:
      return state.get('bioArtProSuperBrainSubtitle');
    case constants.GET_BIO_ART_PRO_SUPER_BRAIN_TEXT:
      return state.get('bioArtProSuperBrainText');

    case constants.GET_BIO_ART_PRO_SINGER_TITLE:
      return state.get('bioArtProSingerTitle');
    case constants.GET_BIO_ART_PRO_SINGER_SUBTITLE:
      return state.get('bioArtProSingerSubtitle');
    case constants.GET_BIO_ART_PRO_SINGER_TEXT:
      return state.get('bioArtProSingerText');

    case constants.GET_BIO_HOB_TITLE:
      return state.get('bioHobbyTitle');
    case constants.GET_BIO_HOB_SUBTITLE:
      return state.get('bioHobbySubTitle');
    case constants.GET_BIO_HOB_ITEM:
      return state.get('bioHobbyItem');
    case constants.GET_BIO_HOB_ITEM_2:
      return state.get('bioHobbyItem2');
    case constants.GET_BIO_HOB_ITEM_3:
      return state.get('bioHobbyItem3');
    case constants.GET_BIO_HOB_ITEM_4:
      return state.get('bioHobbyItem4');

    default:
      return state;
    }
};
