
import ShuffleText from 'shuffle-text';

//防抖函数
function debounce(fn, delay) {
  let timer = null
  //返回一个函数，oninput事件函数
  return function (...args) {
    //借助闭包，访问外部函数的变量 timer
    if (timer !== null) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      console.log(this); //指向input
      //call改变fn函数的this指向，由window 指向 input
      fn.apply(this, args);
    }, delay);
  };
};


// 1.定义timer
// 2.函数传递参数...args
// 3.判断timer是否为0
// 4.根据immediate，选择立刻执行，还是等待delay执行，定义setTimeout，apply

function debounce3(fn, delay, immediate) {
  let timer = null
  //返回一个函数，oninput事件函数
  return function (...args) {
    //借助闭包，访问外部函数的变量 timer
    if (timer !== null) {
      clearTimeout(timer)
    }

    timer = setTimeout(() => {
      // console.log(this); //指向input
      //call改变fn函数的this指向，由window 指向 input

      if(!immediate)fn.apply(this, args);
    }, delay);


    if(immediate) fn.apply(this, args);

  };
};


function debounce2(func, wait, immediate) {
  // 'private' variable for instance
  // The returned function will be able to reference this due to closure.
  // Each call to the returned function will share this common timer.
  var timeout;

  // Calling debounce returns a new anonymous function
  return function() {
    // reference the context and args for the setTimeout function
    var context = this,
      args = arguments;

    // Should the function be called now? If immediate is true
    //   and not already in a timeout then the answer is: Yes
    var callNow = immediate && !timeout;

    // This is the basic debounce behaviour where you can call this
    //   function several times, but it will only execute once
    //   [before or after imposing a delay].
    //   Each time the returned function is called, the timer starts over.
    clearTimeout(timeout);

    // Set the new timeout
    timeout = setTimeout(function() {

      // Inside the timeout function, clear the timeout variable
      // which will let the next execution run when in 'immediate' mode
      timeout = null;

      // Check if the function already ran with the immediate flag
      if (!immediate) {
        // Call the original function with apply
        // apply lets you define the 'this' object as well as the arguments
        //    (both captured before setTimeout)
        func.apply(context, args);
      }
    }, wait);

    // Immediate mode and no wait timer? Execute the function..
    if (callNow) func.apply(context, args);
  }
};


/////////////////////////////////
// DEMO:

//function onMouseMove(e){
//  console.clear();
//  console.log(e.x, e.y);
//}

// Define the debounced function
//var debouncedMouseMove = debounce(onMouseMove, 50);

// Call the debounced function on every mouse move
//window.addEventListener('mousemove', debouncedMouseMove);



function throttle(func, wait = 50){
  let lastTime = 0;
  return function(...args) {
    let now = +new Date();
    if (now - lastTime > wait) {
      lastTime = now;
      func.apply(this, args);
    }
  };
};

function shuffleLetters(e){
    let text = new ShuffleText(e.target);
    text.start();
  }

const Shuffle = props => {
  return <div onMouseEnter={throttle(shuffleLetters, 733)}>{props.children}</div> ;
};

export default Shuffle;
