import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {actionCreators } from '../../store';

import "../styles.css";

import Shuffle from '../../../../common/shuffle/index'

import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { BioTitle, LogoBILI, BioSubTitle, BioArtProSingerWrapper, BioItem, BioSpace } from '../../style'

class BioArtProSinger extends PureComponent {

  render(){
    const {bioArtProSingerTitle, bioArtProSingerSubTitle, bioArtProSingerText} = this.props;
    return (<div>
              <BioArtProSingerWrapper className={"bioartprosingwrapper-style-phone"}>
              <Container fluid className={"no-gutters mx-0 px-0"}>
                <Row>
                <Col sm={1}>
                 <LogoBILI onClick={()=> window.open('https://space.bilibili.com/385046335', "_blank")}/>
                </Col>
                  <Col>
                    {bioArtProSingerTitle.map((item)=>{
                        return (
                          <Row key = {item}>
                            <Col key = {item}>
                             <BioTitle key = {item}>
                              <Shuffle>
                              {item}
                              </Shuffle>
                             </BioTitle>
                          </Col>
                        </Row>
                          )
                        })
                      }
                  </Col>
                </Row>
                  <Row>
                    <Col>
                     <BioSpace />
                  </Col>
                  </Row>
                  <Row>
                    <Col>
                    <BioSubTitle>
                      {bioArtProSingerSubTitle}
                    </BioSubTitle>
                    </Col>
                  </Row>

                      {bioArtProSingerText.map((item)=>{
                          return (
                            <Row key = {item}>
                              <Col key = {item}>
                               <BioItem key = {item}>
                                {item}
                               </BioItem>
                            </Col>
                          </Row>
                            )
                          })
                        }
               </Container>
               </BioArtProSingerWrapper>
         </div>
    )
  }
}

const mapStateToProps = (state)=>({
    bioArtProSingerText: state.getIn(['home','bioArtProSingerText']),
    bioArtProSingerTitle: state.getIn(['home','bioArtProSingerTitle']),
    bioArtProSingerSubTitle: state.getIn(['home','bioArtProSingerSubTitle']),

});

export default connect(mapStateToProps, null)(BioArtProSinger);
