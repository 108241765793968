import React, {PureComponent} from 'react';
import {actionCreators } from '../../store';

import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import "../styles.css";

import {BioArtProMusicListWrapper} from '../../style'

class BioArtMusicList extends PureComponent {

  render(){
    return (<div>
              <BioArtProMusicListWrapper className="bioartpromusiclistwrapper-style-phone">
              <Container fluid className={"no-gutters mx-0 px-0"}>
                <Row>
                  <Col>
                    <iframe title="wangyiyun-music-list"
                            frameBorder="no"
                            border="0"
                            marginWidth="0"
                            marginHeight="0"
                            width="130%"
                            height="300%"
                            src="//music.163.com/outchain/player?type=0&id=7063771090&auto=0&height=430">
                            </iframe>
                  </Col>
                </Row>
               </Container>
               </BioArtProMusicListWrapper>
            </div>
        )
    }
}

export default BioArtMusicList;
