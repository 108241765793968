import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import "../styles.css";

import { BioTitle, LogoWYY, BioSubTitle, BioArtProMusicComposerWrapper, BioItem,BioSpace } from '../../style'

class BioArtProMusicComposer extends PureComponent {

  render(){
    const {bioArtProMusicComposerTitle, bioArtProMusicComposerSubTitle, bioArtProMusicComposerText} = this.props;
    return (<div>
              <BioArtProMusicComposerWrapper className={"bioartpromusiccomposerwrapper-style-phone"}>
              <Container fluid className={"no-gutters mx-0 px-0"}>
                <Row>
                  <Col sm={1}>
                   <LogoWYY onClick={()=> window.open("https://music.163.com/#/artist?id=12313040", "_blank")}/>
                  </Col>
                  <Col>
                    {bioArtProMusicComposerTitle.map((item)=>{
                        return (
                          <Row key = {item}>
                            <Col key = {item}>
                             <BioTitle key = {item}>
                             {item}
                             </BioTitle>
                          </Col>
                        </Row>
                          )
                        })
                      }
                  </Col>
                </Row>
                  <Row>
                    <Col>
                     <BioSpace />
                  </Col>
                  </Row>

                  <Row>
                    <Col>
                    <BioSubTitle>
                      {bioArtProMusicComposerSubTitle}
                    </BioSubTitle>

                    </Col>
                  </Row>

                      {bioArtProMusicComposerText.map((item)=>{
                          return (
                            <Row key = {item}>
                              <Col key = {item}>
                               <BioItem key = {item}>
                                {item}
                               </BioItem>
                            </Col>
                          </Row>
                            )
                          })
                        }
               </Container>
               </BioArtProMusicComposerWrapper>
         </div>
    )
  }
}

const mapStateToProps = (state)=>({
    bioArtProMusicComposerText: state.getIn(['home','bioArtProMusicComposerText']),
    bioArtProMusicComposerTitle: state.getIn(['home','bioArtProMusicComposerTitle']),
    bioArtProMusicComposerSubTitle: state.getIn(['home','bioArtProMusicComposerSubTitle']),

});

export default connect(mapStateToProps, null)(BioArtProMusicComposer);
