import * as constants from './constants';

export const closeBackGroundImageNow = () => ({
  type: constants.CLOSE_BACKGROUND_IMAGE
});


/*

export const getList = () => {
  return (dispatch) => {
    axios.get('/api/headerList.json').then((res) => {
      const data = res.data;
      dispatch(changeList(data.data));
    }).catch(() => {
      console.log('error');
    })
  }
};


*/
