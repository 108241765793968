import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {actionCreators } from '../../store';

import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import "../styles.css";

import ShuffleText from 'shuffle-text';

import {LogoWeibo, BioTitle, BioNumForTitle, BioSubTitle,BioEngWrapper, BioItem, BioSpace } from '../../style'

class BioEngineer extends PureComponent {

  //防抖函数
  debounce(fn, delay) {
    let timer = null
    //返回一个函数，oninput事件函数
    return function (...args) {
      //借助闭包，访问外部函数的变量 timer
      if (timer !== null) {
        clearTimeout(timer)
      }
      timer = setTimeout(() => {
        console.log(this); //指向input
        //call改变fn函数的this指向，由window 指向 input
        fn.apply(this, args);
      }, delay);
    };
  };


  throttle(func, wait = 50){
    let lastTime = 0;
    return function(...args) {
      let now = +new Date();
      if (now - lastTime > wait) {
        lastTime = now;
        func.apply(this, args);
      }
    };
  };


  shuffleLetters(e){
    var text = new ShuffleText(e.target);
    text.start();
  }

  render(){
    const {bioEngineerItem, bioEngineerItem2, bioEngineerSubTitle, bioEngineerTitle} = this.props;
    return (<div>
              <BioEngWrapper className="bioengwrapper-style-phone">
              <Container fluid className={"no-gutters mx-0 px-0"}>

              <Row>
                <Col>
                <BioNumForTitle>
                  ONE
                </BioNumForTitle>
                </Col>
              </Row>
                <Row>
                  <Col>
                  <BioTitle>
                    {bioEngineerTitle}
                  </BioTitle>
                  </Col>
                </Row>
                <Row>
                  <Col>
                   <BioSpace />
                </Col>
                </Row>

                <Row>
                  <Col>
                  <BioSubTitle onMouseEnter={this.throttle(this.shuffleLetters, 733)}>
                    {bioEngineerSubTitle.get(0)}
                  </BioSubTitle>
                  </Col>
                </Row>

                  {bioEngineerItem.map((item)=>{
                      return (
                        <Row key = {item}>
                          <Col key = {item}>
                           <BioItem key = {item}>
                            {item}
                           </BioItem>
                        </Col>
                      </Row>
                        )
                      })
                    }

                  <Row>
                    <Col>
                     <BioSpace />
                  </Col>
                  </Row>
                  <Row>
                    <Col sm={1}>
                      <LogoWeibo onClick={()=> window.open("https://www.weibo.com/liliwish", "_blank")}/>
                    </Col>
                    <Col>
                    <BioSubTitle  onMouseEnter={this.throttle(this.shuffleLetters, 733)}>
                      {bioEngineerSubTitle.get(1)}
                    </BioSubTitle>
                    </Col>
                  </Row>

                      {bioEngineerItem2.map((item)=>{
                          return (
                            <Row key = {item}>
                              <Col key = {item}>
                               <BioItem key = {item}>
                               {item}
                               </BioItem>
                            </Col>
                          </Row>
                            )
                          })
                        }

               </Container>
               </BioEngWrapper>
         </div>
    )
  }
}

const mapStateToProps = (state)=>({
    bioEngineerItem: state.getIn(['home','bioEngineerItem']),
    bioEngineerTitle: state.getIn(['home','bioEngineerTitle']),
    bioEngineerItem2: state.getIn(['home','bioEngineerItem2']),
    bioEngineerSubTitle: state.getIn(['home','bioEngineerSubTitle'])


});

export default connect(mapStateToProps, null)(BioEngineer);
