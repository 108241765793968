import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {actionCreators } from '../../store';
import Typerwriter from 'typewriter-effect';

import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import "../styles.css";

import {ByebyeSocialLinksWrapper,BioSpace,NavImgContainer,NavImg,LogoWeibo,LogoIns,LogoWYY,LogoBILI, BioTitle,BioSubTitle} from '../../style'

class ByebyeSocialLinks extends PureComponent {
  render(){
    return (<div>
              <ByebyeSocialLinksWrapper className="byebyesociallinkswrapper-style-phone">
              <Container fluid className={"no-gutters mx-0 px-0"}>
                <Row>
                 <Col>
                    <BioTitle>
                      <Typerwriter  options={{
                       strings: ['THANKS!'],
                       autoStart: true,
                       loop: true,
                     }}
                       onInit={(typerwriter) =>{
                        typerwriter.start();
                     }} />
                   </BioTitle>
                  </Col>
                </Row>
                <Row><BioSpace/></Row>
                <Row>
                 <Col>
                  <BioSubTitle>More? On My Social Links</BioSubTitle>
                  </Col>
                </Row>
                <Row><BioSpace/></Row>
                <Row>
                  <Col>
                  <NavImgContainer>
                    <NavImg className='left'>
                    <LogoWeibo onClick={()=> window.open("https://www.weibo.com/liliwish", "_blank")}/>
                    </NavImg>
                    <NavImg className='left'>
                    <LogoIns onClick={()=> window.open("https://www.instagram.com/liliwish/", "_blank")}/>
                    </NavImg>
                    <NavImg className='left'>
                    <LogoWYY onClick={()=> window.open("https://music.163.com/#/artist?id=12313040", "_blank")}/>
                    </NavImg>
                    <NavImg className='left'>
                    <LogoBILI onClick={()=> window.open("https://space.bilibili.com/385046335", "_blank")}/>
                    </NavImg>
                  </NavImgContainer>
                  </Col>
                </Row>

                <Row><BioSpace/></Row>
                <Row>
                  <Col>
                   <BioSubTitle>@李礼是只攻城狮 @liliwish</BioSubTitle>
                  </Col>
                </Row>
                <Row><BioSpace/></Row>
                <Row>
                  <Col>
                   <BioSubTitle>Email:wishlili66@outlook.com</BioSubTitle>
                  </Col>
                </Row>
               </Container>
               </ByebyeSocialLinksWrapper>
         </div>
    )
  }
}

const mapStateToProps = (state)=>({

});

export default connect(mapStateToProps, null)(ByebyeSocialLinks);
